<template>


<div class="container">
    <modal-tool-transfer :thisModal="modalNewToolTransfer" modalId="modalNewToolTransfer" title="Transfer" :editTool="editTool" @confirmed="confirmedToolTransfer" />

    <h3>Moj alat</h3>

    <div class="col-lg-12 control-section">

        <div>
            <ejs-grid
                ref="allTools"
                :dataSource="allTools" 
                class="all-tools-grid"
                :allowFiltering='true'
                :allowSorting='true'
                :showColumnMenu='true'
                :filterSettings='filterSettings'
                :allowPaging='true' 
                :pageSettings='pageSettings'
                :recordDoubleClick="toolDBClick"
                :allowResizing='true'
                :allowTextWrap='true'

                >
                <e-columns>
                    <e-column field='id' headerText='ID' width='120' textAlign='Right' :isPrimaryKey='true' :visible='false' ></e-column>

                    
                    <e-column field='image_url' :allowFiltering='false' class="image-column" :allowSorting='false' :showColumnMenu='false' headerText='Slika' width='120' textAlign='Center' :template="toolsImage"></e-column>
                    <e-column field='name' headerText='Naziv' width='120' textAlign='Left' :template="toolsNameTemplate"></e-column>
                    <e-column field='manufacturer' headerText='Proizvodjač' width='120' textAlign='Left' :visible='false'></e-column>
                    <e-column field='type_obj.name' headerText='Tip' width='120' textAlign='Left'></e-column>
                    <e-column field='value' headerText='Vrednost' width='120' textAlign='Right' :visible='false'></e-column>

                    <e-column field='holder_display' headerText='Lokacija' width='120' textAlign='Left' :visible='false'></e-column>

                    <e-column field='approved_by.username' :valueAccessor='fullnameAccessor' headerText='Obradio' width='130' :visible='false'></e-column>

                    <e-column field='updated_at' :valueAccessor='dateTimeAccessor' headerText='Poslednja izmena' width='200' format="yMd HH:mm" textAlign='Right' :visible='true'></e-column>
                    <e-column field='modified_by.username' :valueAccessor='fullnameAccessor' headerText='Poslednji izmenio' width='130' :visible='false'></e-column>
                    <e-column field='created_at' :valueAccessor='dateTimeAccessor' headerText='Kreirano' width='200' format="yMd HH:mm" textAlign='Right' :visible='false'></e-column>
                    <e-column field='created_by.username' :valueAccessor='fullnameAccessor' headerText='Kreirao' width='130' :visible='false'></e-column>

                    <e-column headerText='' :visible='true' textAlign='Right' :template="toolsCMD"></e-column>

                </e-columns>
            </ejs-grid>  
        </div>


    </div>


</div>
</template>

<script>
    import ToolsService from '@/service/ToolsService';
    import ToolsCMD from '@/components/grid-templates/ToolsCMD.vue';
    import { Modal } from 'bootstrap'
    import ModalToolTransfer from '@/components/modals/tools/ModalToolTransfer.vue'


    import UtilService from '@/service/UtilService.js';
    import { Sort, ColumnMenu, Filter, Group, Edit, Aggregate, Resize, Toolbar, PdfExport, ExcelExport } from "@syncfusion/ej2-vue-grids";
    import {mapState} from 'vuex'


    export default {

        name: "EmployeeAbsences",
        props: ['employee'],

        provide: {
            grid: [Sort, ColumnMenu, Filter, Group, Edit, Aggregate, Resize, Toolbar, PdfExport, ExcelExport ]
        },

        components: {
            ModalToolTransfer
        },

        data() {
            return {
            
                allTools: [],

                filterSettings: { type: "Excel" },
                pageSettings: { pageCount: 20},
                editSettings: { allowEditing: true, allowAdding: true, allowDeleting: true },
                editTool: undefined,
                modalNewToolTransfer: undefined,

                toolsImage: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "IMAGE"
                            },   
                        }
                    };
                },
                
                toolsNameTemplate: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "NAME"
                            },   
                        }
                    };
                },

                toolsCMD: function() {
                    return {
                        template: {
                            extends: ToolsCMD,
                            propsData: {
                                column: "EMP_CMD"
                            },
                            
                        }

                    };
                },
            }
        },



        created() {
            this.loadAllTools();

        },

        mounted() {
            this.modalNewToolTransfer = new Modal(document.getElementById('modalNewToolTransfer'), {backdrop: 'static', keyboard: false});
        },

        computed: {

            ...mapState([
                'user',
            ]),

        },

        
        methods: {
            
            formatDate: UtilService.formatDate,
            formatDateTime: UtilService.formatDateTime,
        
            fullnameAccessor(field, data) {
                let firstField = field.split('.')[0];
                let userData = data?.[firstField];
                console.log(userData);
                if (!userData?.first_name) return;
                return userData.first_name + " " + userData.last_name; 
            },

            dateTimeAccessor(field, data) {
                return data[field] ? this.formatDateTime(data[field]) : '/';
            },



            loadAllTools() {
                let query = {
                    'worker': this.employee.id,
                }
                ToolsService.getAllTools(query).then(async (result)=>{
                    this.allTools = ToolsService.prepareToolsData(result.data);
                });
            },


            toast(message, type) {
                this.$toasted.show(message, { 
                    type: type,
                    theme: "toasted-primary", 
                    position: "top-center", 
                    duration : 3000
                });
            },

            toolDBClick(event) {
                console.log(event);
                this.showToolTransferModal(event.rowData);
            },



            showToolTransferModal(selected) {
                this.editTool = selected;
                this.modalNewToolTransfer.show();
            },

            confirmedToolTransfer() {
                this.loadAllTools();
            }

        },
    }
</script>


<style>
    .emp-carnet-grid table tr.non-working-day-row td {
        background: rgb(216, 216, 216);
    }
    .emp-carnet-grid table tr.non-working-day-row:hover td.e-rowcell {
        background: rgb(199, 199, 199) !important;
    }

    .emp-carnet-grid table tr.not-appeared-row td {
        background: rgb(255, 104, 104);
    }
    .emp-carnet-grid table tr.not-appeared-row:hover td.e-rowcell {
        background: rgb(255, 80, 80) !important;
    }

    .emp-carnet-grid .orientationcss .e-headercelldiv {

        width: 100px;
        margin-left: -40px !important;
    }

    .emp-carnet-grid .e-summaryrow .e-summarycell {

        padding-left: 0.7em;
        padding-right: 0.7em;
        text-align: center !important;
    }

    .emp-carnet-grid .e-footerpadding {
        padding-right: 14px;
        text-align: center;
    }

    .emp-carnet-grid .e-checkbox-disabled .e-frame.e-icons:not(.e-check) {
        display: none !important;
    }

    .emp-carnet-grid .e-headercontent .e-table {
        height: 200px;
    }

    
</style>