<template>
    <div class="modal fade" :id="modalId" tabindex="-1" :aria-labelledby="modalId + 'Label'" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 v-if="isRecipient" class="modal-title" id="exampleModalLabel"> Zahtev za preuzimanje alata </h5>
                    <h5 v-else class="modal-title" id="exampleModalLabel"> {{ title }} </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body">
                    <form id="tool-transfer-form">
                        <div v-if="editTool" class="container d-grid gap-2">
                            <h2>{{editTool.name}}</h2>
                            <div class="text-center">
                                <div class="images-cover">
                                    <silent-box :gallery="toolImagesSBox"></silent-box>
                                </div>

                                    <small>Lokacija alata:</small>
                                <template v-if="editTool.location_status === 'NOT_ASSIGNED'">
                                    <h2>Nedodeljen alat</h2>
                                </template>
                                <template v-else-if="editTool.location_status === 'WAREHOUSE'">
                                    <h2>Skladište: <strong>{{editTool.warehouse_obj.name}}</strong></h2>
                                </template>
                                <template v-else-if="editTool.location_status === 'WORKER'">
                                    <h2>Radnik: <strong>{{editTool.worker_obj.first_name}} {{editTool.worker_obj.last_name}}</strong></h2>
                                </template>
                                <h1><font-awesome-icon icon="arrow-down" /></h1>
                            </div>


                            <template v-if="isRecipient">
                                <h2>Radnik: <strong>{{user.first_name}} {{user.last_name}}</strong></h2>
                            </template>
                            <template v-else>
                                <div class="btn-group" role="group" aria-label="Lokacija alata">
                                    <input v-model="transfer_location_status" value="WORKER" type="radio" class="btn-check" name="location_status" id="location-worker" autocomplete="off" checked>
                                    <label class="btn btn-lg btn-outline-primary" for="location-worker">Radnik</label>

                                    <input :disabled="isRecipient" v-model="transfer_location_status" value="WAREHOUSE" type="radio" class="btn-check" name="location_status" id="location-warehouse" autocomplete="off">
                                    <label class="btn btn-lg btn-outline-primary" for="location-warehouse">Skladište</label>
                                </div>

                                <div class="form-floating" v-if="transfer_location_status === 'WORKER'">


                                                    <v-select v-if="employees && toolTransfer"
                                                        :inputId="'inputEmployee'"
                                                        :options="employees"
                                                        :reduce="col => col.id"
                                                        label="full_name" v-model="toolTransfer.to_user"
                                                        :selectable="option => !toolTransfer.to_user || toolTransfer.to_user !== option.id" 
                                                        placeholder = "Izaberite zaposlenog"
                                                        :clearable = "false"
                                                        class="" id="inputEmployee"
                                                        >
                                                        <template slot="option" slot-scope="option">
                                                            <h5 class="m-0">
                                                                <img :src="getAvatarUrl(option)" class="tool-select-emp-circle template-avatar" width="50"/>

                                                                {{ option.first_name }} {{ option.last_name }}
                                                            </h5>
                                                        </template>

                                                        <template slot="selected-option" slot-scope="option">
                                                            <h5 class="m-0">
                                                                <img :src="getAvatarUrl(option)" class="tool-select-emp-circle template-avatar" width="50"/>

                                                                {{ option.first_name }} {{ option.last_name }}
                                                            </h5>
                                                        </template>

                                                    </v-select>




                                    <!-- <select v-model="toolTransfer.to_user" :disabled="!isHolder && !isManager" class="form-select" aria-label="Default select example" required>
                                        <option disabled :value="undefined">Izaberi zaposlenog</option>
                                        <option v-for="employee in employees" :key="employee.id" :value="employee.id">{{employee.first_name}} {{employee.last_name}}</option>
                                    </select> -->

        <!-- 
                                    <template>

                                    <br>
                                    <h5>Izaberi radnika ili neka skenira kod iz aplikacije</h5>
                                    <qrcode-vue :value="qrData" size="200" level="H"></qrcode-vue>

                                    </template> -->
                                </div>

                                <div class="form-floating" v-if="transfer_location_status === 'WAREHOUSE'">

                                    <select :disabled="modalRole == 'REQUESTER'" v-model="toolTransfer.to_warehouse" class="form-select" aria-label="Default select example" required>
                                        <option disabled :value="undefined">Izaberi skladište</option>
                                        <option v-for="warehouse in warehouses" :key="warehouse.id" :value="warehouse.id">{{warehouse.name}}</option>
                                    </select>
                                    <label for="floatingInput">Skladište:</label>
                                    <div class="invalid-feedback">
                                        Skladište je obavezno polje.
                                    </div>
                                </div>

                                <div v-if="modalRole === 'ADMIN'" class="form-check mt-4 force-accept">
                                    <input v-model="forceAccept" class="form-check-input" type="checkbox" value="" id="forceAccept">
                                    <label class="form-check-label" for="forceAccept">
                                        Zaključi razmenu (ne treba druga strana da potvrdi)
                                    </label>
                                </div>
                            </template>

                            <!-- <form id="warehouse-form">
                                <div v-if="warehouse" class="form-floating">
                                    <input v-model="warehouse.name" class="form-control" type="text" placeholder="Naziv:" aria-label="Naziv skladišta alata" required>
                                    <label for="floatingInput">Naziv skladišta alata:</label>
                                    <div class="invalid-feedback">
                                        Naziv je obavezno polje.
                                    </div>
                                </div>

                            </form> -->
                        </div>
                    </form>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal"> Zatvori </button>
                    <button @click="submitTransfer()" type="button" class="btn btn-primary"> Potvrdi </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ToolsService from '@/service/ToolsService.js';
    import UtilService from '@/service/UtilService.js';
    import {mapState} from 'vuex'
    // import QrcodeVue from 'qrcode.vue'

    export default {

        props: {
            modalId: String,
            title: String,
            editTool: Object,
            thisModal: Object
        },

        components: {
            // QrcodeVue,
        },
        
        data() {
            return {
                transfer_location_status: "WORKER",

                forceAccept: false,

                toolTransfer: {}
            }
        },

        computed: {
            ...mapState([
                'toolTypes',
                'warehouses',
                'allActiveUsers',
                'user',
                'userAssignment'
            ]),

            employees() {
                let emp = UtilService.deepClone(this.allActiveUsers);
                emp.forEach(element => {
                    element.full_name = element.first_name + " " + element.last_name;
                });
                return emp.sort((a, b) => a?.first_name?.localeCompare(b?.first_name));
            },

            qrData() {
                if (this.editTool.code) return "REQUEST|" + this.editTool.code;
                else return "REQUEST|" + this.editTool.id;
            },

            workingHourPerm() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            },

            // toolLocation() {
            //     if (this.editTool?.location_status === 'WORKER') return 
            // },

            modalRole() {
                if (this.user.role === 'ADMIN') return "ADMIN";
                if (this.workingHourPerm) return "MANAGER";
                if (this.editTool?.location_status === 'WORKER' && this.editTool.worker_obj.id === this.user.id) return "HOLDER";
                return "REQUESTER"
            },

            isHolder() {
                if (this.editTool?.location_status === 'WORKER' && this.editTool.worker_obj.id === this.user.id) return true;
                return false;
            },


            isManager() {
                if (this.user.role === "MANAGER" || this.user.role === "ADMIN" || this.userAssignment?.pwo?.pwo_role == 1)
                    return true;
                return false;
            },

            isRecipient() {
                return !this.isManager && !this.isHolder
            },



            toolImagesSBox() {
                let images = []
                let imageId = 1;
                if (this.editTool?.image_url){
                    let obj = {
                        src: this.editTool?.image_url,
                        thumbnailHeight: "200px",
                        thumbnailWidth: "200px",
                        description: "Slika " + imageId++
                    }

                    images.push(obj)

                    this.editTool?.gallery?.forEach(img => {
                        let obj = {
                            src: img.url,
                            thumbnailHeight: "200px",
                            thumbnailWidth: "200px",
                            description: "Slika " + imageId++
                        }

                        images.push(obj)
                    });
                }
                return images

            }


        },

        methods: {

            validate() {
                let form = document.getElementById("tool-transfer-form");
                form.classList.add('was-validated');

                if (!form.checkValidity())
                    return false;

                return true;
            },

            submitTransfer() {
                if (!this.validate()) return

                this.createTransfer();
            },

            prepareTransferData() {
                if (this.transfer_location_status === 'WORKER') {
                    this.toolTransfer.to_warehouse = null;
                } else if (this.transfer_location_status === 'WAREHOUSE') {
                    this.toolTransfer.to_user = null;
                }


                if (this.forceAccept) {
                    this.toolTransfer.transfer_status = "TRANSFERRED"
                    this.toolTransfer.acceptedAt = UtilService.formatDatePattern(null, "YYYY-MM-DDTHH:mm:SS")
                }
            },

            createTransfer() {
                this.prepareTransferData()

                ToolsService.createToolTransfer(this.toolTransfer).then(result => {
                    console.log(result);
                    this.thisModal.hide();
                    this.$emit('confirmed');
                })
            },

            getTransferStatus() {
                if (this.isManager) return "IN_TRANSFER";
                else if (this.editTool?.location_status === 'WORKER' && this.editTool.worker_obj.id === this.user.id) return "IN_TRANSFER";
                else if (this.editTool?.location_status === 'WAREHOUSE' && this.isManager) return "IN_TRANSFER";
                else if (this.editTool?.location_status === 'NOT_ASSIGNED' && this.isManager) return "IN_TRANSFER";
                else return "REQUESTED";

            },

            initData() {
                this.toolTransfer = {
                    tool: this.editTool.id,

                    transfer_status: this.getTransferStatus(),
                    from_user: null,
                    from_warehouse: null,
                    to_user: null,
                    to_warehouse: null,
                    comment: "",
                }

                if (this.editTool?.location_status === 'WORKER') this.toolTransfer.from_user = this.editTool.worker;
                else if (this.editTool?.location_status === 'WAREHOUSE') this.toolTransfer.from_warehouse = this.editTool.warehouse;

                if (!this.isHolder) this.toolTransfer.to_user = this.user.id;

                console.log("InitData");
                console.log(this.toolTransfer);
            },

            setUpListener() {
                let myModalEl = document.getElementById(this.modalId)
                myModalEl.addEventListener('shown.bs.modal', () => {
                    this.initData();
                })
            },

            loadAllWarehouses() {
                this.$store.dispatch('getAllWarehouses')
            },


            loadAllWorkers() {
                this.$store.dispatch('loadAllActiveUsers')
            },

            getAvatarUrl(employee) {
                return employee.avatar_url?employee.avatar_url:require('@/assets/profilna.png')
            },
        },

        mounted() {
            this.setUpListener();
        },


        created() {
            this.loadAllWorkers();
            this.loadAllWarehouses();

        }

    }
</script>

<style scoped>
.v-select.form-control{
    height: 4.5em;
}

.tool-image-top {
    max-width: 100%;
    max-height: 500px;
    display: block;
    margin: 0 auto;
}

.force-accept {
    font-size: 1.3em;
}

.tool-select-emp-circle {
    width: 50px;
    height: 50px;
    object-fit: cover;
    border-radius: 100%;
}
</style>